import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "../src/assets/laguages/en/common_en.json"
import userList_en from "../src/assets/laguages/en/userList_en.json"
import organizationAccount_en from "../src/assets/laguages/en/organizationAccount_en.json"
import status_en from "../src/assets/laguages/en/status_en.json"
import nfc_en from "../src/assets/laguages/en/nfc_en.json"

import common_vie from "../src/assets/laguages/vie/common_vie.json"
import userList_vie from "../src/assets/laguages/vie/userList_vie.json"
import organizationAccount_vie from "../src/assets/laguages/vie/organizationAccount_vie.json"
import status_vie from "../src/assets/laguages/vie/status_vie.json"
import report_vie from "../src/assets/laguages/vie/report_vie.json"
import nfc_vie from "../src/assets/laguages/vie/nfc_vie.json"
import joinRequest_vie from "../src/assets/laguages/vie/joinRequest_vie.json"
import feedModeration_vie from "../src/assets/laguages/vie/feedModeration_vie.json"

const resources = {
    en: {
        translation: {
            ...common_en,
            ...userList_en,
            ...organizationAccount_en,
            ...status_en,
            ...nfc_en
        }
    },
    vi: {
        translation: {
            ...common_vie,
            ...userList_vie,
            ...organizationAccount_vie,
            ...status_vie,
            ...report_vie,
            ...nfc_vie,
            ...joinRequest_vie,
            ...feedModeration_vie
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem("lang") ? String(window.localStorage.getItem("lang")) : 'vi'
    });

export default i18n;
