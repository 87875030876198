import axios from "axios";
import ApiMicroService from "./config-api";

// social
export const loginSocialMedia = async (accessToken: any, type: string) => {
  try {
    if (type === "google") {
      const result = await ApiMicroService.post(
        `/qrnet-service/user/auth/google`,
        accessToken
      );
      return result.data;
    }
    if (type === "facebook") {
      const result = await ApiMicroService.post(
        `/qrnet-service/user/auth/facebook`,
        accessToken
      );
      return result.data;
    }
    if (type === "linkedin") {
      const result = await ApiMicroService.post(
        `/qrnet-service/user/auth/linkedin`,
        accessToken
      );
      return result.data;
    }
    if (type === "apple") {
      const result = await ApiMicroService.post(
        `/qrnet-service/user/auth/apple`,
        accessToken
      );
      return result.data;
    }
  } catch (error) {
    throw error;
  }
};
// email
export const requestEmailLogin = async (userInfo: { email: string }) => {
  try {
    const results = await ApiMicroService.post(
      "/qrnet-service/user/admin/verification/request/email",
      userInfo
    );
    return results.data;
  } catch (error) {
    throw error;
  }
};

export const getEmailPermissionsets = async (data: {
  verification_id: number;
  email: string;
  code: string;
  type: string;
}) => {
  try {
    const result = await ApiMicroService.post(
      "/qrnet-service/user/admin/auth/email",
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const requestEmailLoginForTeam = async (userInfo: { email: string }) => {
  try {
    const results = await ApiMicroService.post(
      "/qrnet-service/user/verification/request/email",
      userInfo
    );
    return results.data;
  } catch (error) {
    throw error;
  }
};

export const getEmailPermissionsetsForTeam = async (data: {
  verification_id: number;
  email: string;
  code: string;
  type: string;
}) => {
  try {
    const result = await ApiMicroService.post(
      "/qrnet-service/user/auth/email",
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
// phone
export const requestPhoneLogin = async (userInfo: any) => {
  try {
    const results = await ApiMicroService.post(
      "/qrnet-service/user/verification/request/phone",
      userInfo
    );
    return results.data;
  } catch (error) {
    throw error;
  }
};

export const getPhonePermissionsets = async (data: any) => {
  try {
    const result = await ApiMicroService.post(
      "/qrnet-service/user/auth/phone",
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserDetail = async (id: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/user/admin/detail/${id}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfile = async (id: any) => {
  try {
    const result = await ApiMicroService.get(`/qrnet-service/profile/${id}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};
// list user customer
export const getListUserCustomer = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/user/admin/list/customer`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
// list user admin
export const getListUserAdmin = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/user/admin/list/admin`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
// create admin QRnet
export const createAdminQRnet = async (data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/user/admin/qrnet/create`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
// edit admin QRnet
export const editAdminQRnet = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/user/admin/qrnet/${id}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAccessTokenLinkedIn = async (
  redirect_uri: string,
  client_id: string,
  client_secret: string,
  code: string
) => {
  try {
    const result = await axios.get(
      `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=${client_id}&client_secret=${client_secret}&code=${code}&redirect_uri=${redirect_uri}`,
      {
        headers: {
          "Content-Type": "application.json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
// verification request
export const updateVerificationRequest = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization/request_verify/${id}`, data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getListRequestJoinOrg = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/profile`, { params }
    )
    return result.data
  } catch (error) {
    throw error
  }
}

export const getPosts = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/social/post/all`, { params }
    )
    return result.data
  } catch (error) {
    throw error
  }
}

export const deletePost = async (post_id: number, data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/social/post/admin_delete/${post_id}`, data
    )
    return result.data
  } catch (error) {
    throw error
  }
}

export const getListReasonDeletePost = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/social/post/reason_delete`, { params }
    )
    return result.data
  } catch (error) {
    throw error
  }
}

export const createPost = async (data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/social/post/admin_create`, data
    )
    return result.data
  } catch (error) {
    throw error
  }
}

export const hiddenPost = async (data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/social/post/admin_hidden`, data
    )
    return result.data
  } catch (error) {
    throw error
  }
}

export const activePost = async (id: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/social/post/admin_active/${id}`
    )
    return result.data
  } catch (error) {
    throw error
  }
}