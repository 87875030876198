import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getUser } from "../../utils/common";
import { MENU_ADMIN_SIDEBAR, MENU_ORG_SIDEBAR } from "../../constants/layouts/sidebar";

import { Layout, Menu } from "antd"
import SubMenu from "antd/lib/menu/SubMenu";
import { LogoCollapsed } from "../../assets/icon/LogoCollapsed";
import { LogoAdminEnterprise } from "../../assets/icon/LogoAdminEnterprise";
import { LogoAdminQRnet } from "../../assets/icon/LogoAdminQRnet";
// import ClickOutside from "../common/ClickOutside";

const BasicSidebar = () => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const userInfo = getUser()
    const isShowSidebar = useSelector((state: any) => state.commonReducer.isShowSidebar || false)

    return <Layout.Sider
        className={"bgWhite sidebar"}
        trigger={null}
        collapsible
        collapsed={isShowSidebar}
        width={208}
        collapsedWidth={60}
        style={{
            overflow: "auto",
            height: "100vh",
            position: "sticky",
            top: 0,
            left: 0
        }}
    >

        <div className="sidebar__logo">
            <Link to="/">
                {isShowSidebar ? <LogoCollapsed /> :
                    userInfo.role === "admin" ? <LogoAdminQRnet /> : <LogoAdminEnterprise />
                }
            </Link>
        </div>

        <Menu
            className="border-none ant-menu"
            mode={isShowSidebar ? "vertical" : "inline"}
            selectedKeys={[`${pathname.slice(1).replace("/", "_")}`]}
        // defaultOpenKeys={["users"]}
        >
            {userInfo.role === "admin" ? (
                MENU_ADMIN_SIDEBAR.map((item: any) => {
                    if (Array.isArray(item?.child) && item?.child.length > 0) {
                        return <SubMenu key={item.key} icon={item.icon} title={t(item.name)} className="active">
                            {item.child.map((child: any) => {
                                return (
                                    <Menu.Item key={child.key}>
                                        {/* <ClickOutside> */}
                                        <Link to={child.link} style={{ color: "white" }}>{t(child.name)}</Link>
                                        {/* </ClickOutside> */}
                                    </Menu.Item>
                                )
                            })}
                        </SubMenu>
                    }
                    return <Menu.Item key={item.key} icon={item.icon} className="active">
                        {/* <ClickOutside> */}
                        <Link to={item.path} style={{ color: "white" }} >
                            {t(item.name)}
                        </Link>
                        {/* </ClickOutside> */}
                    </Menu.Item>
                })

            ) : (
                MENU_ORG_SIDEBAR.map((item: any) => {
                    if (Array.isArray(item?.child) && item?.child.length > 0) {
                        return <SubMenu key={item.key} icon={item.icon} title={t(item.name)}>
                            {item.child.map((child: any) => {
                                return (
                                    <Menu.Item key={child.key}>
                                        {/* <ClickOutside> */}
                                        <Link to={child.link} style={{ color: "white" }}>{t(child.name)}</Link>
                                        {/* </ClickOutside> */}
                                    </Menu.Item>
                                )
                            })}
                        </SubMenu>
                    }
                    return <Menu.Item key={item.key} icon={item.icon} className="active">
                        {/* <ClickOutside> */}
                        <Link to={item.path} style={{ color: "white" }} >
                            {t(item.name)}
                        </Link>
                        {/* </ClickOutside> */}
                    </Menu.Item>
                })
            )}
        </Menu>
    </Layout.Sider >
}

export default BasicSidebar